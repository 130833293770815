import React from 'react'
import './footer.scss';
import FooterLogo from '../../../assets/logo/footer-logo.svg';
import AppIcon from '../../../assets/icons/app.svg';
import GooglePayIcon from '../../../assets/icons/google-pay.svg';
export default function Footer() {
  return (
    <div>
        <footer>
            <div className='footer-first-child'>
                <div className='container'>
                    <div className='footer-alignment'>
                        <div className='footer-logo'>
                            <img src={FooterLogo} alt="FooterLogo"/>
                        </div>
                        <div className='get-app'>
                            <h2>Get the App</h2>
                            <div className='store-image'>
                                <img src={AppIcon} alt="AppIcon"/>
                                <img src={GooglePayIcon} alt="GooglePayIcon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='copy-right'>
                    <p>© 2022 Lifesummary. All rights reserved</p>
                </div>
            </div>
        </footer>
    </div>
  )
}
