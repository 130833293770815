import React from 'react'
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import './your-plan.scss';
import { getUserInfo } from "../../Helpers/utils/user.util"
import SubHeader from '../Common/SubHeader/index';
import * as authUtil from "../../Helpers/utils/auth.util";

export default function YourPlan() {
    const userInfo = getUserInfo();
    const [modalOpen, setModalOpen] = useState(false);
    const [State, setState] = useState();
    const [ContentState, setContentState] = useState();
    const history = useHistory();

    
    const Logout = async () => {
        authUtil.logout();
        history.push("/")
        window.location.reload();
        toast.success("Successfully logout")
    };

  return (
    <div>
         <>
            <SubHeader/>
        </>
        <div className='your-plan-alignment'>
            <div className='container'>
                <div className='page-title'>
                    <h1><span>Din</span> Gjeldende plan</h1>
                </div>
                <div className='box-center-alignment'>
                    <div className='plan-view-box'>
                        <div className='text-grid'>
                            <div className='text-grid-items'>
                                <p>Plan Description:</p>
                            </div>
                            <div className='text-grid-items'>
                                <span>{userInfo?.currentPlanDetails?.planDescription}</span>
                            </div>
                        </div>
                        <div className='text-grid'>
                            <div className='text-grid-items'>
                                <p>Plan Cost:</p>
                            </div>
                            <div className='text-grid-items'>
                                <span>{userInfo?.currentPlanDetails?.planCost}</span>
                            </div>
                        </div>
                        <div className='text-grid'>
                            <div className='text-grid-items'>
                                <p>Plan Type:</p>
                            </div>
                            <div className='text-grid-items'>
                                <span>{userInfo?.currentPlanDetails?.planType}</span>
                            </div>
                        </div>
                        <div className='text-grid'>
                            <div className='text-grid-items'>
                                <p>Payment ID:</p>
                            </div>
                            <div className='text-grid-items'>
                                <span>-</span>
                            </div>
                        </div>
                        <div className='text-grid'>
                            <div className='text-grid-items'>
                                <p>Start Date:</p>
                            </div>
                            <div className='text-grid-items'>
                                <span>{userInfo?.currentPlanDetails?.startDate}</span>
                            </div>
                        </div>
                        <div className='text-grid'>
                            <div className='text-grid-items'>
                                <p>End Date:</p>
                            </div>
                            <div className='text-grid-items'>
                                <span>{userInfo?.currentPlanDetails?.endDate}</span>
                            </div>
                        </div>
                        {/* <div className='text-grid'>
                            <div className='text-grid-items'>
                                <p>Days Left:</p>
                            </div>
                            <div className='text-grid-items'>
                                <span>14 days to expire</span>
                            </div>
                        </div> */}
                            <button onClick={()=>setModalOpen(!modalOpen)}>dowonload link</button>
                       
                        
                    </div>
                   
                </div>
            </div>
        </div>
        {modalOpen && (
        <div className="cus-modal">
          <div className="modal">
            <div className="cus-modal-alignment-header">
              <div>
                <p>Dowonload Links</p>
              </div>
              <div onClick={() => setModalOpen(false)}>
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="cus-modal-body-price">
                <h2>
                    <a href="https://life-summary.s3.us-east-2.amazonaws.com/app-release.apk" target="_blank">
                        Click for Android 
                    </a> 
                </h2>
                <h2>
                    <a href="https://life-summary.s3.us-east-2.amazonaws.com/lifesummary.exe" target="_blank">
                      Click for Dekstop 
                    </a> 
                </h2>
            </div>
         
          </div>
        </div>
      )}
    </div>
  )
}
