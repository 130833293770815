import React from 'react'
import Footer from '../layout/Footer';
import HeroSection from './HeroSection';
import './home.scss';
import LifesummaryGives from './LifeSummaryGives';
import SafeWork from './SafeWork';
import Service from './Service';
export default function Home() {
  return (
    <div>
      <>
        <HeroSection/>
      </>
      <>
        <LifesummaryGives/>
      </>
      <>
        <Service/>
      </>
      <>
        <SafeWork/>
      </>
      <>
        <Footer/>
      </>
    </div>
  )
}
