import React from 'react'
import './service.scss';
import GetInTouchIcon from '../../../assets/icons/get-in-touch.png';
export default function Service() {
  return (
    <div>
        <section className='service-left-alignment'>
            <div className='grid'>
                <div className='grid-items'></div>
                <div className='grid-items'>
                    <h1><a>Legal services</a> made easy, <span>safe and</span> affordable.</h1>
                    <p>
                        Just as online banking has made it easy to handle money, Justify 
                        makes it easy to arrange the legal agreements and documents that mean something to you and yours.
                    </p>
                    <div className='get-in-touch'>
                        <img src={GetInTouchIcon} alt="GetInTouchIcon"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
