import './App.css';
import { Route, BrowserRouter as Router } from "react-router-dom";
import './styles/mixins/global.scss';
import Register from './Components/Auth/register';
import signin from './Components/Auth/signin';
import forgotpassword from './Components/Auth/forgotpassword';
import Home from './Components/Home/index';
import Plan from './Components/Plan/index';
import YourPlan from './Components/YourPlan';
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Newpass from './Components/Auth/Newpass';
import { Redirect } from 'react-router-dom';

function App() {
    const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("userinfo") !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
  return (
    <>
      <Router>
        <Route exact path="/" component={Home}/>
        <Route exact path="/register" component={Register}/>
        <Route exact path="/signin" component={signin}/>
        <Route exact path="/forgot-password" component={forgotpassword}/>
        <Route exact path="/newpass" component={Newpass}/>
        <ProtectedRoute exact path="/plan" component={Plan}/>
        <ProtectedRoute exact path="/your-plan" component={YourPlan}/>
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
