import React from 'react'
import './life-summary-gives.scss';
import HandIcon from '../../../assets/icons/hand.svg';
import PowerIcon from '../../../assets/icons/power.svg';
import DigitalIcon from '../../../assets/icons/digital.svg';
export default function LifesummaryGives() {
  return (
    <div>
        <section className='life-summary-gives-alignment'>
            <div className='container'>
                <div className='page-title'>
                    <h1><span>Lifesummary</span> gives you</h1>
                </div>
                <div className='grid'>
                    <div className='grid-items'>
                        <div className='icon-alignment'>
                            <div className='icon-design'>
                                <img src={HandIcon} alt="HandIcon"/>    
                            </div>             
                        </div>
                        <h2>Cohabitation contract</h2>
                        <p>
                            Get full control of ownership interests 
                            in housing, equity, renovation and joint finances.
                        </p>
                    </div>
                    <div className='grid-items'>
                        <div className='icon-alignment'>
                            <div className='icon-design'>
                                <img src={PowerIcon} alt="PowerIcon"/>    
                            </div>             
                        </div>
                        <h2>Future power of attorney</h2>
                        <p>
                            Choose for yourself who 
                            can look after your personal and financial interests.
                        </p>
                    </div>
                    <div className='grid-items'>
                        <div className='icon-alignment'>
                            <div className='icon-design'>
                                <img src={DigitalIcon} alt="DigitalIcon"/>    
                            </div>             
                        </div>
                        <h2>Digital will</h2>
                        <p>
                            Get full control of ownership interests 
                            in housing, equity, renovation and joint finances.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
