import React, { useState } from 'react'
import queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";
import { NavLink } from 'react-router-dom';
import { ApiPut } from '../../Helpers/ApiData';

const Newpass = () => {
  const [inputValue, setinputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [CmdDesc, setCmdDesc] = useState();
  const idValue = queryString.parse(
    typeof window !== "undefined" ? window.location.search : null
  );

  const history = useHistory();
  
  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setinputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  let sendletter = document.getElementById("sendLetter");

  function addClass() {
    document.body.classList.add("sent");
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (inputValue && !inputValue.newPassword) {
      errors["password"] = "*Skriv inn passord";
      formIsValid = false;
    }
    //  else if (
    //   !inputValue.newPassword.match(
    //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,15}$/
    //   )
    // ) {
    //   formIsValid = false;
    //   errors["password"] =
    //     "* Vennligst skriv inn et passord på mellom 7 og 15 tegn som inneholder én liten bokstav,\n én stor bokstav, ett tallsiffer og ett spesialtegn";
    // }

    setErrors(errors);
    return formIsValid;
  };

  const handleOnNewpass = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await ApiPut(`user/forget?token=${idValue.token}`,inputValue)
      .then(async(res) => {
        toast.success("Passord tilbakestilt");
          history.push("/signin");
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
    return (
        <div>
             <div className='auth-banner'>
          <div className="auth-white-box">
            <h3>Enter New Password?</h3> 
            <p>Passord</p>
            <div className='input-alignment'>
              <div className='input'>
                <input type="password"
                            className="form-control"
                            id="password"
                            value={inputValue.newPassword}
                            name="newPassword"
                            placeholder="********"
                            // required
                            onChange={(e) => handleOnChnage(e)}
                      />
                       <span style={{ color: "red" }}>
                            {errors["password"]}
                          </span>
              </div>
            </div>
            <div className='button'>
              <button onClick={(e) => handleOnNewpass(e)}>tilbakestille</button>
            </div>
            <div class="last-child">
                    <span>
                      Har du ikke en konto?{" "}
                      <NavLink to="/signin">&nbsp; Melde deg på</NavLink>
                    </span>
            </div>
          </div>
        </div>
        </div>
      )
    }

export default Newpass