import React from 'react'
import Header from '../../layout/Header';
import './hero-section.scss';
import Logo from '../../../assets/logo/logo.svg';
import { NavLink } from 'react-router-dom';
export default function HeroSection() {
  return (
    <div>
        <div className='hero-section'>
            <>
                <Header/>
            </>
            <div className='container'>
                <div className='logo-alignment'>
                    <img src={Logo} alt="Logo"/>
                </div>
                <h1>Your most important
                information - in a digital safe.</h1>
                <p>
                    Lifesummary gives you control over all the information you gather in your life, and gives 
                    you the opportunity to share it with someone you have close. Everything in a safe place!
                </p>
                <NavLink to="/register">
                    <button>Register</button>
                </NavLink>
            </div>
        </div>
    </div>
  )
}
