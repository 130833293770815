import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import './auth.scss';
import EyesIcon from '../../assets/icons/eye.svg';
import { ApiGet, ApiPost } from "../../Helpers/ApiData";
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-modern-calendar-datepicker";
import { setUserInfo } from "../../Helpers/utils/user.util";
export default function Register() {

  const [inputValue, setinputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [CmdDesc, setCmdDesc] = useState();

  const history = useHistory();

  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setinputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  let sendletter = document.getElementById("sendLetter");

  function addClass() {
    document.body.classList.add("sent");
  }

  //const maximumDate = new Date()

  const maximumDate = {
    year:  new Date().getFullYear(),
    month: new Date().getMonth()+1,
    day: new Date().getDate(),
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (inputValue && !inputValue.firstName) {
      formIsValid = false;
      errors["firstName"] = "*Vennligst skriv inn fornavn!";
    }
    if (inputValue && !inputValue.lastName) {
      formIsValid = false;
      errors["lastName"] = "*Vennligst skriv inn etternavn!";
    }
    if (inputValue && !inputValue.phone) {
      formIsValid = false;
      errors["phone"] = "*Vennligst skriv inn telefonen!";
    }
    if (
      (inputValue && !inputValue.email) ||
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Vennligst skriv inn e-post!";
    }

    // if (inputValue && !inputValue.password) {
    //   errors["password"] = "*Skriv inn passord";
    //   formIsValid = false;
    // } else if (
    //   !inputValue.password.match(
    //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,15}$/
    //   )
    // ) {
    //   formIsValid = false;
    //   errors["password"] =
    //     "*Vennligst skriv inn et passord på mellom 7 og 15 tegn som inneholder én liten bokstav,\n én stor bokstav, ett tallsiffer og ett spesialtegn";
    // }

    // if (inputValue && !inputValue.confirm_password) {
    //   errors["confirm_password"] = "*Vennligst skriv inn bekrefte passord";
    //   formIsValid = false;
    // } else if (inputValue.password != inputValue.confirm_password) {
    //   errors["confirm_password"] = "* Passord stemmer ikke";
    //   formIsValid = false;
    // }
    // if (inputValue && !inputValue.password) {
    //   formIsValid = false;
    //   errors["password"] = "*Vennligst skriv inn et passord";
    // }
    // if (inputValue && !inputValue.confirm_password) {
    //   formIsValid = false;
    //   errors["confirm_password"] = "*Vennligst skriv inn bekrefte passord";
    // }
    if (inputValue && !inputValue.address) {
      formIsValid = false;
      errors["address"] = "*Vennligst skriv inn adresse!";
    }

    // if (inputValue && !inputValue.birthDate) {
    //   formIsValid = false;
    //   errors["birthDate"] = "*Vennligst skriv inn fødselsdato!";
    // }
    if (inputValue && !inputValue.zip) {
      formIsValid = false;
      errors["zip"] = "*Vennligst skriv inn zip!";
    }
    if (inputValue && !inputValue.city) {
      formIsValid = false;
      errors["city"] = "*Vennligst skriv inn City!";
    }
    if (inputValue && !inputValue.countryCode) {
      formIsValid = false;
      errors["countryCode"] = "*Vennligst skriv inn landskode!";
    }
    setErrors(errors);
    return formIsValid;
  };

  const formatInputValue = () => {
    if (!inputValue?.birthDate) return "";
    // return `
    //   ${new Date(inputValue?.birthDate).getDate} /
    //   ${new Date(inputValue?.birthDate).getMonth} /
    //   ${new Date(inputValue?.birthDate).getFullYear}
    // `;

    return `
    ${new Date(inputValue?.birthDate).getDate()} /
    ${new Date(inputValue?.birthDate).getMonth()} /
    ${new Date(inputValue?.birthDate).getFullYear()}
  `;
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const signUp = (e) => {
    e.preventDefault();
    if (validateForm()) {
      inputValue.roles = "user";
      ApiPost("user/registration", inputValue)
        .then((res) => {
          // res?.data?.payload?.userData?.token token
          // res?.data?.payload?.userData?.user user
          const logindata ={email:inputValue.email, password:inputValue.password }
            if (res.status == 200) {
               ApiPost("user/login", logindata)
               .then((res) => {
                setUserInfo(res?.data?.payload?.user);
                if (res.status == 200) {
                  history.push({
                    pathname: "/plan",
                    state: { email: res?.data?.payload?.userData?.user?.email },
                  });
                }
              })
              .catch((err) => {
                toast.error(err);
              });
            }
          setinputValue({});
          // toast.success(res?.data?.message);
          toast.success("Bruker registrere vellykket");
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  return (
    
    <div>
      <ToastContainer />
  <div className='auth-banner'>
        <div className="auth-white-box">
          <h3>Registrere</h3> 
          <p>Velkommen til <span>Livssammendrag</span></p>
          <div className='input-alignment'>
          <div className='input-grid'>
              <div className='input'>
                <input type="text"
                            className="form-control"
                            id="firstName"
                            value={inputValue.firstName}
                            name="firstName"
                            placeholder="Fornavn"
                            // required
                            onChange={(e) => handleOnChnage(e)}/>
                            <span style={{ color: "red" }}>
                            {errors["firstName"]}
                          </span>
              </div>
              <div className='input'>
                <input type="text"
                            className="form-control"
                            id="lastName"
                            value={inputValue.lastName}
                            name="lastName"
                            placeholder="Etternavn"
                            // required
                            onChange={(e) => handleOnChnage(e)}/>
                            <span style={{ color: "red" }}>
                            {errors["lastName"]}
                          </span>
              </div>
            </div>
            <div className='input'>
              <input type="email"
                            className="form-control"
                            id="email"
                            value={inputValue.email}
                            name="email"
                            placeholder='E-post'
                            // required
                            onChange={(e) => handleOnChnage(e)} />
                            <span style={{ color: "red" }}>
                            {errors["email"]}
                          </span>
            </div>
            <div className='input'>
            <DatePicker
                            value={
                              inputValue?.birthDate
                                ? {
                                    day: new Date(
                                      inputValue?.birthDate
                                    ).getDate(),
                                    month: new Date(
                                      inputValue?.birthDate
                                    ).getMonth(),
                                    year: new Date(
                                      inputValue?.birthDate
                                    ).getFullYear(),
                                  }
                                : null
                            }
                            onChange={(date) => {
                              let newDate = new Date();
                              newDate.setDate(date?.day);
                              newDate.setMonth(date?.month);
                              newDate.setFullYear(date?.year);
                              setErrors({ ...errors, birthDate: "" });
                              setinputValue({
                                ...inputValue,
                                birthDate: newDate,
                              });
                            }}
                            inputPlaceholder="Select a date"
                            formatInputText={formatInputValue}
                            // maximumDate={{
                            //   year: new Date().getFullYear(),
                            //   day: new Date().getDate(),
                            //   month: new Date().getMonth(),
                            // }}
                            maximumDate={maximumDate}
                            inputClassName="my-custom-input"
                            shouldHighlightWeekends
                          />
              {/* <input type="text" placeholder='DD/MM/YYYY'/> */}
              {/* <DatePicker /> */}
              <span style={{ color: "red" }}>
                            {errors["birthDate"]}
                          </span>
            </div>
            <div className='input'>
              <input type="text"
                            className="form-control"
                            id="countryCode"
                            value={inputValue.countryCode}
                            name="countryCode"
                            placeholder="Skriv inn landskode"
                            maxLength={2}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            // required
                            onChange={(e) => handleOnChnage(e)} />
                            <span style={{ color: "red" }}>
                            {errors["countryCode"]}
                          </span>
            </div>
            <div className='input'>
              <input type="text"
                            className="form-control"
                            id="phone"
                            onKeyPress={bindInput}
                            value={inputValue.phone}
                            name="phone"
                            placeholder='Telefon'
                            maxLength={10}
                            // required
                            onChange={(e) => handleOnChnage(e)} />
                            <span style={{ color: "red" }}>
                            {errors["phone"]}
                          </span>
            </div>
            <div className='input-grid'>
              <div className='input'>
                <input type="password"
                            className="form-control"
                            id="password"
                            value={inputValue.password}
                            name="password"
                            placeholder='Password'
                            // required
                            onChange={(e) => handleOnChnage(e)} />
                    <span style={{ color: "red" }}>
                            {errors["password"]}
                          </span>
                <div className='icon-alignment'>
                  <img src={EyesIcon} alt="EyesIcon"/>
                </div>
              </div>
              <div className='input'>
                <input type="password"
                            className="form-control"
                            id="confirm_password"
                            value={inputValue.confirm_password}
                            name="confirm_password"
                            placeholder='Bekreft passord'
                            // required
                            onChange={(e) => handleOnChnage(e)} />
                            <span style={{ color: "red" }}>
                            {errors["confirm_password"]}
                          </span>
                <div className='icon-alignment'>
                  <img src={EyesIcon} alt="EyesIcon"/>
                </div>
              </div>
            </div>
            <div className='input'>
              <input 
                            id="address"
                            name="address"
                            value={inputValue.address}
                            className="form-control"
                            placeholder='Adresse'
                            onChange={(e) => handleOnChnage(e)} />
                            <span style={{ color: "red" }}>
                            {errors["address"]}
                          </span>
            </div>
            <div className='input-grid'>
              <div className='input'>
                <input type="text"
                            className="form-control"
                            id="city"
                            value={inputValue.city}
                            name="city"
                            placeholder='City'
                            // required
                            onChange={(e) => handleOnChnage(e)} />
                            <span style={{ color: "red" }}>
                            {errors["city"]}
                          </span>
              </div>
              <div className='input'>
                <input type="text"
                            className="form-control"
                            id="zip"
                            onKeyPress={bindInput}
                            value={inputValue.zip}
                            name="zip"
                            placeholder='Post kode'
                            maxLength={6}
                            // required
                            onChange={(e) => handleOnChnage(e)} />
                            <span style={{ color: "red" }}>{errors["zip"]}</span>
              </div>
            </div>
          </div>
          <div className='button'>
            <button onClick={(e) => signUp(e)}>Register</button>
          </div>
          <div className="last-child">
            <p>Already a member?  { "" }<NavLink to="/signin">
                <span>sign in</span>
              </NavLink>
              </p>
          </div>
        </div>
      </div>
    </div>
  )
}
