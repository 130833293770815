const protocol = "https";
const host = "api.lifesummary.no";
// const host = "192.168.29.133:5000";

// const port = ;
const hostUrl = `${protocol}://${host}/api/v1/`;

export const API = {
  protocol: protocol,
  host: host,
  hostUrl: hostUrl,
};
