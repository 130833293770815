import './auth.scss';
import EyesIcon from '../../assets/icons/eye.svg';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiPost } from '../../Helpers/ApiData';
import { useState } from 'react';
import { useHistory } from "react-router";


export default function Forgotpassword() {
  const [inputValue, setinputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [CmdDesc, setCmdDesc] = useState();

  const history = useHistory();

  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setinputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  let sendletter = document.getElementById("sendLetter");

  function addClass() {
    document.body.classList.add("sent");
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (
      (inputValue && !inputValue.email) ||
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Vennligst skriv inn e-post!";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handalSendMail = async (e) => {
    e.preventDefault();
    if (validateForm) {
      await ApiPost("user/forget", inputValue)
        .then(async(res) => {
          setinputValue({});
          toast.success("Vennligst sjekk din e-post");
        })
        .catch((res) => {
          toast.error("Bruker finnes ikke");
        });
    }
  };
  return (
    <div>
         <div className='auth-banner'>
      <div className="auth-white-box">
        <h3>Forgot Password?</h3> 
        <p>Please enter the email ID associated with your account.</p>
        <div className='input-alignment'>
          <div className='input'>
            <input type="email"
                    className="form-control"
                    id="email"
                    value={inputValue.email}
                    name="email"
                    placeholder="E-post"
                    // required
                    onChange={(e) => handleOnChnage(e)}/>
          </div>
            
        </div>
        <div className='button'>
          <button onClick={(e) => handalSendMail(e)}>Send mail</button>
        </div>
        <div className="last-child">
          <p>Already a member? { "" }
              <NavLink to="/signin">
             <span>Register</span>
             </NavLink>
          </p>
        </div>
      </div>
    </div>
    </div>
  )
}
