import React from 'react'
import './safe-work.scss';
import LeptopImage from '../../../assets/images/leptop.png';
export default function SafeWork() {
  return (
    <div>
        <div className='safe-work-banner'>
            <div className='container'>
                <div className='page-title'>
                    <h1><span>This is how</span> the safe works</h1>
                </div>
                <div className='grid'>
                    <div className='grid-items'>
                        <div className='counter-alignment'>
                            <div className='counter-design'>
                                <span>1</span>
                            </div>
                        </div>
                        <h1>Log in securely</h1>
                        <p>
                            To be absolutely sure that you are you, log 
                            in using BankID . Safe and easy!
                        </p>
                    </div>
                    <div className='grid-items'>
                        <div className='counter-alignment'>
                            <div className='counter-design'>
                                <span>2</span>
                            </div>
                        </div>
                        <h1>Take care of information</h1>
                        <p>
                            Upload your document or information in one of the safe's many rooms. If you allow 
                            it, we can automatically retrieve information about your properties and vehicles.
                        </p>
                    </div>
                    <div className='grid-items'>
                        <div className='counter-alignment'>
                            <div className='counter-design'>
                                <span>3</span>
                            </div>
                        </div>
                        <h1>Share with someone</h1>
                        <p>
                            Your most important information can now be shared with someone you have close. They log in 
                            with their own BankID when they are to gain access.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='leptop-image-alignment'>
                <img src={LeptopImage} alt="LeptopImage"/>
            </div>
        </div>
    </div>
  )
}
