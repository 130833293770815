import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import  queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";
import { setUserInfo } from "../../Helpers/utils/user.util";
import './auth.scss';
import EyesIcon from '../../assets/icons/eye.svg';
import { NavLink } from 'react-router-dom';
import { ApiPost } from '../../Helpers/ApiData';

export default function Signin() {

  const [inputValue, setInputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [CmdDesc, setCmdDesc] = useState();

  const history = useHistory();

  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  let sendletter = document.getElementById("sendLetter");

  function addClass() {
    document.body.classList.add("sent");
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (
      (inputValue && !inputValue.email) ||
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Vennligst skriv inn e-post!";
    }
    setErrors(errors);
    return formIsValid;
  };

  const contactUS = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await ApiPost("user/login", inputValue)
        .then(async (res) => {
          // res?.data?.payload?.userData?.token token
          // res?.data?.payload?.userData?.user user
          await setUserInfo(res?.data?.payload?.user);
          toast.success(res?.data?.message);
          if (res.data.payload.user.currentPlanDetails.planDescription) {
            history.push({
              pathname: "/your-plan",
              state: {
                email: res?.data?.payload?.user?.email,
                showToast: true,
              },
            });
          } else {
            history.push({
              pathname: "/plan",
              state: {
                email: res?.data?.payload?.user?.email,
                showToast: true,
              },
            });
          }
          setInputValue({});
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    const eml = queryString.parse( window.location.search)
    const email = eml.email;
    const password = eml.password;
    const userData ={email:email, password:password }
if (userData.email) {
     ApiPost("user/login", userData)
        .then(async (res) => {
          // res?.data?.payload?.userData?.token token
          // res?.data?.payload?.userData?.user user
          await setUserInfo(res?.data?.payload?.user);
          toast.success(res?.data?.message);
          if (res.data.payload.user.currentPlanDetails.planDescription) {
            history.push({
              pathname: "/profile",
              state: {
                email: res?.data?.payload?.user?.email,
                showToast: true,
              },
            });
          } else {
            history.push({
              pathname: "/payment",
              state: {
                email: res?.data?.payload?.user?.email,
                showToast: true,
              },
            });
          }
          setInputValue({});
        })
        .catch((err) => {
          toast.error(err);
        });
      }
  },[]);
  return (
    <div>
    <div className='auth-banner'>
      <div className="auth-white-box">
        <h3>Sign in</h3> 
        <p>Welcome to <span>Lifesummary</span></p>
        <form onSubmit={(e) => contactUS(e)}>
        <div className='input-alignment'>
        <div className="form-group input">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={inputValue.email}
                            name="email"
                            placeholder="jennadavis@gmail.com"
                            // required
                            onChange={(e) => handleOnChnage(e)}
                          />
                          <span style={{ color: "red" }}>
                            {errors["email"]}
                          </span>
                          </div>
                      <div className="form-group input">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={inputValue.password}
                            name="password"
                            placeholder="********"
                            // required
                            onChange={(e) => handleOnChnage(e)}
                          />
                          <span style={{ color: "red" }}>
                            {errors["password"]}
                          </span>
              <div className='icon-alignment'>
                <img src={EyesIcon} alt="EyesIcon"/>
              </div>
            </div>
            <div className='forgot-password'>
                <NavLink to="/forgot-password">
                <span>Forgot Password?</span>
                </NavLink>
            </div>
        </div>
        <div className='button'>
           <button>Sign in</button>
         </div>
        </form>
        <div className="last-child">
          <p  onClick={()=>history.push("/register")}>Already a member? <span>Register</span></p>
        </div>
      </div>
    </div>
  </div>
  )
}
