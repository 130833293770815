import React from 'react'
import './header.scss';
import AppleIcon from '../../../assets/icons/apple.svg';
import AndroidIcon from '../../../assets/icons/android.svg';
import TvIcon from '../../../assets/icons/tv.svg';
import { useHistory } from 'react-router-dom';
export default function Header() {
const history =useHistory()
  return (
    <div>
        <div className='container'>
            <div className='header-alignment'>
                <div className='social-media'>
                    <img src={AppleIcon} alt="AppleIcon" onClick={()=>history.push("/signin")}/>
                </div>
                <div className='social-media'>
                    <img src={AndroidIcon} alt="AndroidIcon" onClick={()=>history.push("/signin")} />
                </div>
                <div className='social-media'>
                    <img src={TvIcon} alt="TvIcon" onClick={()=>history.push("/signin")}/>
                </div>
            </div>
        </div>        

    </div>
  )
}
