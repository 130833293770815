import React from 'react'
import './sub-header.scss';
import * as authUtil from "../../../Helpers/utils/auth.util";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from '../../../assets/logo/logo.svg';
export default function SubHeader() {

    const history = useHistory();

    const Logout = async () => {
        
        authUtil.logout();
        history.push("/")
        window.location.reload();
        toast.success("Successfully logout")
        
    };
  return (
    <div>
        <div className='sub-header'>
            <div className='container'>
                <div className='sub-header-alignment'>
                    <div className='logo'>
                        <img src={Logo} alt="Logo"/>
                    </div>
                    <div className='auth-button'>
                        <button onClick={Logout}>Sign Out</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
