import SubHeader from "../Common/SubHeader";
import "./plan.scss";
import CheckIcon from "../../assets/icons/check.svg";
import CheckWhiteIcon from "../../assets/icons/check-white.svg";
import CardInput from "./CardInput";
import React, { useEffect, useState } from "react";
// import axios from "axios";
// import Card from "@material-ui";
// import CardContent from "@material-ui/core/CardContent";
// import TextField from "@material-ui/core/TextField";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
// import { makeStyles } from "@material-ui/core/styles";
// import CardInput from "../Home/payment/CardInput";
// import { Modal, Button } from "react-bootstrap";
// import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";
// import Dialog from "@material-ui/core/Dialog";
// import List from "@material-ui/core/List";
// import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useLocation } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import "../Home/payment/Payment.scss";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
// import { withStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";

// import Close from "../../../Assets/Images/close.png";
import { ApiPost, ApiGetNoAuth, ApiGet, ApiGetwithAuth } from "../../Helpers/ApiData";
import { getUserInfo, setUserInfo } from "../../Helpers/utils/user.util";
export default function Plan() {
  // const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  // State
  const [email, setEmail] = useState("");

  const [loader, setLoader] = useState(false);
  const [planType, setPlanType] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [prime, setIsPrime] = useState();
  const [startBill, setStartBill] = useState();
  const [nextBill, setNextBill] = useState();
  const [modal2, setModal2] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [transactionId, setTrnsactionId] = React.useState();
  const [openPurchase, setOpenPurchase] = useState(false);
  const [allSubscriptionPlan, setAllSubscriptionPlan] = useState([]);
  const [price, setPrice] = useState([]);
  const [currency, setCurrency] = useState("");
  const [planID, setPlanId] = useState();
  const [idForPurchase, setIdForPurchase] = useState();
  const useremail = getUserInfo()

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  // const getUserDetails =async()=>{
  //   await ApiGetwithAuth("user/detail")
  //     .then((res) => {
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  // useEffect(()=>{
  //   getUserDetails()

  // },[])
  // const DialogTitle = withStyles(styles)((props) => {
  //   const { children, classes, onClose, ...other } = props;
  //   return (
  //     <MuiDialogTitle
  //       disableTypography
  //       className={classes.root}
  //       {...other}
  //     >
  //       <Typography variant="h6">{children}</Typography>
  //       {onClose ? (
  //         <IconButton
  //           aria-label="close"
  //           className={classes.closeButton}
  //           onClick={onClose}
  //         >
  //           <CloseIcon />
  //         </IconButton>
  //       ) : null}
  //     </MuiDialogTitle>
  //   );
  // });

  useEffect(() => {
    const useremail = getUserInfo()
    setEmail(useremail.email)
  }, [idForPurchase]);

  const handleModal2 = async () => {
    setModal2(!modal2);
  };

  // const validateForm = () => {
  //   let formIsValid = true;
  //   let errors = {};
  //   if (inputValue && !inputValue.firstName) {
  //     formIsValid = false;
  //     errors["firstName"] = "*Vennligst skriv inn fornavn!";
  //   }
  // }

  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    if (!stripe || !elements) { 
      setLoader(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const res = await ApiPost("payment/pay", {
      email: email,
      planId: idForPurchase,
    });
    const clientSecret = res?.data?.payload?.client_secret;
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });
    if (result.error) {
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        const res = await ApiPost("payment/confirm", {
          planId: idForPurchase,
          email: email,
          paymentId: result?.paymentIntent?.client_secret,
        }).then((res) => {
          if (res.status == 200) {
            setDownloadModalOpen(!downloadModalOpen)
            setModalOpen(!modalOpen)
            setUserInfo(res?.data?.payload?.UserData)
          }
        })
        .catch((err) => {
          toast.error(err);
        });
        setOpenPurchase(false);
        // getPrimeDetails();
        // getUltraPrimeArtist();
        // getSubscriptionPlan();
        toast.success("Innbetaling vellykket fullført!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      } else {
        toast.error("Betaling ikke utført!");
      }
    }
    setLoader(false);
  };

    const handalSelectPlan = (data) =>{
      const latestData = getUserInfo()
    if (!latestData?.currentPlanDetails?.planDescription) {
      if (data?.planCost > 0) {
        setOpenPurchase(true);
        setModalOpen(!modalOpen);
        handlePurchase(data);
        setIdForPurchase(data._id);
      } else {
        activateFreeTrial(data._id);
      }
    }else{
      toast.error("Du har allerede en kjøpsplan.")
    }
  }

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setEmail(location?.state?.email);
    getSubscriptionPlan();
  }, []);

  const getSubscriptionPlan = async () => {
    await ApiGetNoAuth("plan/")
      .then((res) => {
        setAllSubscriptionPlan(res?.data?.payload?.allPlan);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleModal = (e)=>{
    
  }

  const activateFreeTrial = async (planId) => {
    const useremail = getUserInfo()
    await ApiPost("payment/free", {
      email: useremail.email,
      planId: planId,
    })
      .then((res) => {
        if (res.status == 200) {
          toast.success("successfully")
          setUserInfo(res?.data?.payload?.updateUser)
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleClosePurchase = () => {
    setOpenPurchase(false);
  };

  // const handleClick = async () => {
  //   await setOpen(true);
  //   const data = {
  //     _id: transactionId,
  //   };
  //   console.log("data-----", data);
  //   await ApiPost("subscription/update-active-sub", data)
  //     .then((res) => {
  //       console.log("ddd-", res);
  //       window.location.reload();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   window.location.reload();
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCancelsub = async (event) => {
    // await ApiGet("subscription/cancel-subscription")
    //   .then((c) => {
    //     window.location.reload();
    //     handleClick();
    //     handleModal2();
    //     getPrimeDetails();
    //     toast.success("Subscription cancelled successfully");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handlePurchase = async (data) => {
    setPrice(data?.planCost);
    setCurrency(data?.currency);
    setPlanId(data?._id);
    setPlanType(data?.planType);
    setIdForPurchase(data?._id);
    setEmail(useremail.email);
    // console.log(data?._id);
  };

  return (
    <div>
      <ToastContainer />
      <>
        <SubHeader />
      </>

      <div className="plan-alignment">
        <div className="container">
          <div className="page-title">
            <h1>
              <span>Choose</span> your Plan
            </h1>
          </div>
          <div className="grid">
            {allSubscriptionPlan?.length === 0 ? (
              <div className="p-10 d-flex justify-content-center my-10 font-weigth-bold">
                <h1>No Subscription Plan Available Now</h1>
              </div>
            ) : (
              allSubscriptionPlan?.map((data, key) => {
                return (
                  <div className="grid-items">
                    <h2>{data?.planType}</h2>
                    <div className="plan-center-align">
                      <div className="plan-round">
                        <h3>${data?.planCost}</h3>
                      </div>
                    </div>
                    <div className="icon-text-alignment">
                      <div>
                        <img src={CheckIcon} alt="CheckIcon" />
                      </div>
                      <div>
                        <span>{data?.planDescription}</span>
                      </div>
                    </div>
                    <div className="icon-text-alignment">
                      <div>
                        <img src={CheckIcon} alt="CheckIcon" />
                      </div>
                      <div>
                        <span>$ {data?.planCost}</span>
                      </div>
                    </div>
                    <div className="icon-text-alignment">
                      <div>
                        <img src={CheckIcon} alt="CheckIcon" />
                      </div>
                      <div>
                        <span>{data?.validity}</span>
                      </div>
                    </div>
                    <div className="select-plan">
                      <button
                        onClick={() => handalSelectPlan(data)}
                      >
                        Select Plan
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {modalOpen && (
        <div className="cus-modal">
          <div className="modal">
            <div className="cus-modal-alignment-header">
              <div>
                <p>Fill up Information</p>
              </div>
              <div onClick={() => setModalOpen(false)}>
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="cus-modal-body-price">
                <h2>Total billed : {price} </h2>
              <div className="input-alignment-modal">
                <div className="input">
                  <input
                    id="outlined-email-input"
                    helperText={`Email you'll recive updates and receipts on`}
                    margin="normal"
                    variant="outlined"
                    type="email"
                    required
                    value={email}
                    />
                </div>
              </div>
              <div className="card-auto-input">
                <div className="input">
                <CardInput />
                </div>
              </div>
              <div className="pay-button-style">
                <div className="button"  onClick={handleSubmit}>
                  <button onClick={(e)=>handleModal(e)}>Pay</button>
                  {loader ? (
                          <span className="mx-3 spinner spinner-white"></span>
                        ) : null}
                </div>
              </div>
            </div>
         
          </div>
        </div>
      )}

{downloadModalOpen && (
        <div className="cus-modal">
          <div className="modal">
            <div className="cus-modal-alignment-header">
              <div>
                <p>Takk for at du ble med på Livssammendrag</p>
              </div>
              <div onClick={() => setDownloadModalOpen(false)}>
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="cus-modal-body-price">
                <h2>
                    <a href="https://life-summary.s3.us-east-2.amazonaws.com/app-release.apk" target="_blank">
                        Click for Android 
                    </a> 
                </h2>
                <h2>
                    <a href="https://life-summary.s3.us-east-2.amazonaws.com/lifesummary.exe" target="_blank">
                      Click for Dekstop 
                    </a> 
                </h2>
            </div>
         
          </div>
        </div>
      )}
    </div>
  );
}
