import React from "react";
// import { Button } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Plan from "./Plan";
// import Plan from "../../Plan/index"
const stripePromise = loadStripe(`${process.env.REACT_APP_PK_LIVE}`);

const Plans = () => {
  return (
    <div>
      <div>
        <Elements stripe={stripePromise}>
          <Plan />
        </Elements>
      </div>
    </div>
  );
};

export default Plans;
